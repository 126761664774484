import { Box, Button, Theme, Typography, useTheme } from '@mui/material';
//import { jsPDF } from 'jspdf';
import React, { ReactDOM, useEffect, useState } from 'react';
import CalendarView from '../../components/CalendarView/CalendarView';
import CalendarViewer from '../../components/CalendarViewer/CalendarViewer';
import { Header } from '../../components/header/Header';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { Meeting, MeetingResponse } from '../../models/common';
import { meetingServices } from '../../services/meetings/meetings';
import { tokens } from '../../theme';
import Content from '../global/Content';
import MainWrapper from '../global/MainWrapper';

import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFMeetings from './PDFMeetings';

export interface IAppProps {}
function Home(props: IAppProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const authContext = useAuthContext();
  const [meetings, setMeetings] = useState<any[]>([]);
  const [name, setName] = useState<string>('');

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  const handleClick = async () => {
    if (authContext) {
      const service = new meetingServices();
      const response = (await service.getMeetings(authContext.user.token, authContext.user.email)) as MeetingResponse;
    }
  };

  useEffect(() => {
    const _getMeetings = async () => {
      if (authContext) {
        const service = new meetingServices();
        const response = (await service.getMeetings(authContext.user.token, authContext.user.email)) as MeetingResponse;
        setName(authContext.user.name);

        let meeting = response.meetings.map(e => {
          const dateStr = e.schedule.date.split('-');
          const timeStr = e.schedule.initialHour.split(':');
          const date = new Date(Number(dateStr[0]), Number(dateStr[1]) - 1, Number(dateStr[2]), Number(timeStr[0]), Number(timeStr[1]), 0);
          e.orderDate = date;
          return e;
        });
        meeting = meeting
          .filter(e => e.orderDate)
          .sort((a: Meeting, b: Meeting) => (a.orderDate || new Date()).getTime() - (b.orderDate || new Date()).getTime());
        setMeetings(meeting);
      }
    };
    _getMeetings();
  }, []);

  return (
    <MainWrapper>
      <Content
        title="Citas"
        subtitle="Visualización de tus citas"
        instructions="Ubique el evento de la reunión y presione clic. Obtendrá acceso instantáneo a todos los detalles esenciales de la reunión, como la fecha, la hora y las instrucciones para unirse."
      >
        <Box display="flex" justifyContent="end" mt="20px" mb="20px">
          <PDFDownloadLink document={<PDFMeetings meetings={meetings} name={name} />} fileName="meetings.pdf">
            <Button color="secondary" variant="contained">
              <div> Descargar mis citas en PDF </div>
            </Button>
          </PDFDownloadLink>
        </Box>
        {/*}
        <CalendarViewer />
  */}

        <CalendarView />
      </Content>
    </MainWrapper>
  );
}

export default Home;
