import * as React from 'react';

import { Box, Button, Typography } from '@mui/material';
import MainWrapper from '../global/MainWrapper';

import { useNavigate } from 'react-router-dom';
import errorimg from '../../assets/errorimg.png';
import Content from '../global/Content';

type Props = {};
function ErrorComp({}: Props) {
  const navigate = useNavigate();
  return (
    <MainWrapper>
      <Content title="" subtitle="">
        <Box alignItems={'center'} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
          <Typography variant="h2">Acceso no autorizado</Typography>
          <Box component={'img'} src={errorimg} />
          <Button size="large" variant="contained" color="secondary" onClick={() => navigate('/')}>
            Entendido
          </Button>
        </Box>
      </Content>
    </MainWrapper>
  );
}
export default ErrorComp;
