import { CloseOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Header } from '../../components/header/Header';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { Guest, MeetingResponse } from '../../models/common';
import { eventServices } from '../../services/events/events';
import { meetingServices } from '../../services/meetings/meetings';
import { tokens } from '../../theme';
import Content from '../global/Content';
import MainWrapper from '../global/MainWrapper';
import MeetingForm from './MeetingForm';

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { AxiosError } from 'axios';

const example_rows: any = [
  {
    id: 'ME001',
    name: 'Cita con los pedros',
    eventId: 'PR001',
    schedule: {
      date: '2023-06-20',
      initialHour: '15:00',
      endHour: '15:30',
      format: 0, //Fisico
      host: 'Blvd. Los Próceres 24-69 Z-10'
    },
    guests: ['gerardo@correo.com', 'daniel@correo.com', 'gerardo@correo.com']
  },
  {
    id: 'ME002',
    name: 'Cita con los juanes',
    eventId: 'PR001',
    schedule: {
      date: '2023-06-20',
      initialHour: '15:30',
      endHour: '16:00',
      format: 1, //Digital
      host: 'https://meet.google.com/'
    },
    guests: [
      'gerardo@correo.com',
      'daniel@correo.com',
      'gerardo@correo.com',
      'gerardo@correo.com',
      'daniel@correo.com',
      'gerardo@correo.com'
    ]
  }
];

type Props = {};
function Meetings({}: Props) {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalOpen, setmodalOpen] = React.useState<boolean>(false);
  const [isEditing, setisEditing] = React.useState<boolean>(false); //Var to define if the modal form opens in edit mode
  const [initialData, setinitialData] = React.useState<any>(null);
  const [rows, setRows] = React.useState<any>([]);
  const [modalStatusOpen, setmodalStatusOpen] = React.useState<boolean>(false);
  const [statusRows, setstatusRows] = React.useState<any>([]);
  const [statusCount, setStatusCount] = React.useState<Number>(0);

  const authContext = useAuthContext();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      cellClassName: 'name-column--cell',
      flex: 0.2
    },
    {
      field: 'date',
      headerName: 'Fecha',
      valueGetter: (params: any) => {
        return dayjs(params.row.schedule.date).format('YYYY-MM-DD');
      },
      flex: 0.1
    },
    {
      field: 'initialHour',
      headerName: 'Inicia',
      valueGetter: (params: any) => {
        return dayjs(params.row.schedule.initialHour, 'HH:mm').format('HH:mm');
      },
      flex: 0.1
    },
    {
      field: 'endHour',
      headerName: 'Finaliza',
      valueGetter: (params: any) => {
        return dayjs(params.row.schedule.endHour, 'HH:mm').format('HH:mm');
      },
      flex: 0.1
    },
    {
      field: 'guestCount',
      headerName: 'Invitados',
      valueGetter: (params: any) => {
        const guests = params.row.guests as Array<Guest>;
        return `${guests.map(e => e.email).join(',')}`;
      },
      flex: 0.1
    },
    {
      field: 'format',
      headerName: 'Formato',
      valueGetter: (params: any) => {
        return params.row.schedule.format === 'DIGITAL' ? 'Digital' : 'Fisico';
      },
      flex: 0.15
    },
    {
      field: 'host',
      headerName: 'Inv.',
      valueGetter: (params: any) => {
        return params.row.schedule.host;
      },
      flex: 0.25
    },
    {
      field: '',
      sortable: false,
      renderCell: (params: any) => (
        <>
          <IconButton onClick={() => handleEdit(params)}>
            <EditOutlinedIcon />
          </IconButton>
        </>
      )
    }
  ];

  const statusColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.15
    },
    {
      field: 'fileName',
      headerName: 'Nombre',
      flex: 0.35
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.25
    },
    {
      field: 'status',
      headerName: 'Estatus del archivo',
      flex: 0.25,
      renderCell: (cellValues: any) => {
        const { value } = cellValues;
        return value === 'ERROR' ? (
          <>
            <CancelOutlinedIcon /> <span>Archivo con error</span>
          </>
        ) : value === 'PENDING' ? (
          <>
            <AutorenewOutlinedIcon /> <span>Archivo pendiente</span>
          </>
        ) : (
          <>
            <CheckCircleOutlineOutlinedIcon /> <span>Archivo exitoso</span>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    //From here I'll do fetch from the api to retrieve all events from db for current user
    const fetchData = async () => {
      try {
        if (authContext) {
          const service = new meetingServices();
          const response = (await service.getMeetingsFromEvent(authContext.user.token, id)) as MeetingResponse;
          //Change the attributes that need the UI
          setRows(response.meetings);
        }
        //setRows(example_rows);
      } catch (error) {
        setRows([modalOpen]);
      }
    };

    fetchData();
    //setRows(rowsExample);
  }, [modalOpen]);

  /**
   * Method to handle the close event from the modal form
   * @param event event triggered on the dialog
   * @param reason reason that closes the dialog
   * @returns void
   */
  const handleClose = (event: any, reason: any) => {
    //Avoid to close the modal when click outside
    if (reason === 'backdropClick') {
      event.stopPropagation();
      return;
    }
    setmodalOpen(false);
  };

  /**
   * Method to handle the open button for the modal form
   */
  const handleOpen = () => {
    setisEditing(false);
    resetFormValues();
    setmodalOpen(true); //Open modal form
  };

  const handleCreate = async (newItem: any) => {
    const service = new meetingServices();
    const guests = newItem.guests as Array<string>;

    delete newItem.id;
    //newItem.schedule.format = 'fisico';
    //newItem.schedule.host = 'direcciones';
    newItem.guests = guests.map(e => {
      return {
        email: e
      };
    });

    if (authContext) {
      try {
        let response = await service.createMeeting(authContext.user.token, {
          type: 'meeting',
          input: [{ ...newItem }]
        });
        if (Array.isArray(response)) {
          if (!response[0].status) {
            Swal.fire({
              title: 'Error!',
              text: `${response[0].message}`,
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Info!',
              text: `${response[0].message}`,
              icon: 'info'
            });
          }
        }
        response = (await service.getMeetingsFromEvent(authContext.user.token, id)) as MeetingResponse;
        setRows(response.meetings);
        //setRows([...rows, newItem]); //Add new label to state array
        resetFormValues(); //Reset values
      } catch (error) {
        const response = error as AxiosError;
        const message = response.response!.data as any;
        if (Array.isArray(response)) {
          Swal.fire({
            title: 'Error!',
            text: `${message.message[0]}`,
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `${message}`,
            icon: 'error'
          });
        }
      }

      //Change the attributes that need the UI

      setmodalOpen(false); //Close modal
    }
  };

  const handleEdit = (params: GridCellParams) => {
    setinitialData({
      id: params.row.id,
      name: params.row.name,
      eventId: params.row.eventId,
      schedule: {
        date: params.row.schedule.date,
        initialHour: params.row.schedule.initialHour,
        endHour: params.row.schedule.endHour,
        format: params.row.schedule.format === 'DIGITAL' ? 1 : 0, //Fisico
        host: params.row.schedule.host
      },
      guests: params.row.guests.map((guest: any) => guest.email)
    });
    setisEditing(true); //Set form in edit mode
    setmodalOpen(true); //Open modal form
  };

  const editMeeting = async (_updatedItem: any) => {
    setLoading(true);
    const service = new meetingServices();
    const guests = _updatedItem.guests as Array<string>;
    _updatedItem.guests = guests.map(e => {
      return {
        email: e
      };
    });

    if (authContext) {
      try {
        let response = await service.updateMeeting(authContext.user.token, {
          type: 'meeting',
          input: [{ ..._updatedItem }]
        });
        if (Array.isArray(response)) {
          console.log(response);

          if (!response[0].status) {
            Swal.fire({
              title: 'Error!',
              text: `${response[0].message}`,
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Info!',
              text: `${response[0].message}`,
              icon: 'info'
            });
            response = (await service.getMeetingsFromEvent(authContext.user.token, id)) as MeetingResponse;
            setRows(response.meetings);
            //setRows([...rows, newItem]); //Add new label to state array
            setmodalOpen(false); //Close modal
            resetFormValues(); //Reset values
          }
        }
      } catch (error) {
        const response = error as AxiosError;
        const message = response.response!.data as any;
        if (Array.isArray(response)) {
          Swal.fire({
            title: 'Error!',
            text: `${message.message[0]}`,
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `${message}`,
            icon: 'error'
          });
        }
      }
    }

    setLoading(false);
  };

  const resetFormValues = () => {
    setinitialData({
      id: '',
      name: '',
      eventId: id,
      schedule: {
        date: '2023-08-01',
        initialHour: '15:00',
        endHour: '15:30',
        format: 0, //Fisico
        host: ''
      },
      guests: []
    });
  };

  const handleFileUpload = async (event: any) => {
    if (authContext) {
      const service = new meetingServices();
      const file = event.target.files;
      if (file.length > 0) {
        setLoading(true);
        try {
          let response = await service.uploadMeetingFile(authContext.user.token, id, file[0]);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Yay...',
            text: 'Archivo enviado de forma exitosa!'
          });
        } catch (error) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Parece que algo salió mal, intentalo de nuevo más tarde!'
          });
        }
      }
    }
  };

  /**
   * Method to handle the open button for the status of the files
   */
  const handleOpenStatusModal = async () => {
    if (authContext) {
      const service = new meetingServices();
      const response = await service.getMeetingFiles(authContext.user.token, id);
      const count = response.count;
      if (count < 1) {
        Swal.fire({
          title: 'Ooops..!',
          text: `No has cargado ningun archivo para este evento.`,
          icon: 'warning'
        });
      } else {
        console.log(response);
        const data = response.files.map((file: any, key: any) => {
          return { fileName: file.fileName, date: file.date, status: file.status, id: key };
        });

        setStatusCount(count);
        setstatusRows(data);
        setmodalStatusOpen(true);
      }
    }
  };

  return (
    <MainWrapper>
      <Content title={'Citas'} subtitle={'Organiza y agenda tus citas'}>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button color="secondary" variant="contained" onClick={handleOpen} sx={{ marginRight: 2 }}>
            Agendar cita
          </Button>
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: 'none' }}
            id="file-upload"
            multiple={false}
            type="file"
            onChange={handleFileUpload}
            disabled={loading}
          />
          <label htmlFor="file-upload">
            <Button color="secondary" variant="contained" component="span">
              Carga masiva
            </Button>
          </label>
          <Button color="secondary" variant="contained" onClick={handleOpenStatusModal} sx={{ marginLeft: 2 }}>
            Estatus de carga masiva
          </Button>
        </Box>
        <Box
          m="8px 0 0 0"
          height="80vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300]
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400]
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700]
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`
            }
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
          />
        </Box>
        {/**
         * Modal para cargar formulario de citas
         */}
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          maxWidth="md"
          sx={{
            zIndex: 1001
          }}
          PaperProps={{ sx: { width: '100%', backgroundColor: colors.primary[400], zIndex: 10000 } }}
        >
          <DialogTitle
            variant="h2"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Cita
            <IconButton onClick={() => setmodalOpen(false)} color="primary">
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: 'fit-content', overflow: 'hidden' }}>
            <MeetingForm isEditing={isEditing} initialValues={initialData} handleCreate={handleCreate} editMeeting={editMeeting} />
          </DialogContent>
        </Dialog>

        {/**
         * Modal con estatus de cargas masivas
         */}

        <Dialog
          open={modalStatusOpen}
          disableEscapeKeyDown={true}
          maxWidth="md"
          sx={{
            zIndex: 1001
          }}
          PaperProps={{ sx: { width: '100%', backgroundColor: colors.primary[400], zIndex: 10000 } }}
        >
          <DialogTitle
            variant="h2"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Estatus de carga masiva
            <IconButton onClick={() => setmodalStatusOpen(false)} color="primary">
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: 'fit-content', overflow: 'hidden' }}>
            <Box>
              <Typography variant="h3">{`Has cargado ${statusCount} archivos.`}</Typography>
            </Box>
            <Box
              m="8px 0 0 0"
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none'
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none'
                },
                '& .name-column--cell': {
                  color: colors.greenAccent[300]
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: 'none'
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: colors.primary[400]
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                  backgroundColor: colors.blueAccent[700]
                },
                '& .MuiCheckbox-root': {
                  color: `${colors.greenAccent[200]} !important`
                },
                overflow: 'auto'
              }}
              height={'50vh'}
            >
              <DataGrid rows={statusRows} columns={statusColumns} />
            </Box>
          </DialogContent>
        </Dialog>
      </Content>
      {loading ? (
        <Backdrop
          open={true}
          sx={{
            zIndex: theme => theme.zIndex.drawer + 1,
            color: '#fff'
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </MainWrapper>
  );
}
export default Meetings;
