import * as React from 'react';
import Swal from 'sweetalert2';
import login from '../../assets/login.png';

import { CloseOutlined, LockOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { useAuth } from '../../contexts/auth/useAuth';
import { tokens } from '../../theme';

type Props = {};
function Login({}: Props) {
  const [showEvents, setShowEvents] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Handle login logic here
    if (authContext) {
      const response: any = await authContext.login({
        email: email.toLowerCase(),
        password: !showPassword ? email.toLowerCase() : password
      });
      if (response) {
        Swal.fire({
          title: 'Error!',
          text: `${response.message}`,
          icon: 'error'
        });
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleClose = (e: any) => {
    setShowEvents(!showEvents);
  };

  React.useEffect(() => {
    setShowEvents(true);
  }, []);

  return (
    <Container maxWidth="xl" sx={{ heigh: '100%' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
        <Grid item xs={12} sm={6}>
          <img src={login} alt="Login Image" style={{ width: '100%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h2"> Iniciar Sesión </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  sx={{
                    backgroundColor: '#9595fb21'
                  }}
                  InputProps={{
                    style: { fontSize: '1.25rem' },
                    sx: {
                      '&:focus': { outline: 'red' },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fff', // Modify the border color when focused
                        borderWidth: 2 // Modify the border width when focused
                      }
                    }
                  }}
                  InputLabelProps={{
                    sx: { '&.Mui-focused': { color: 'white', fontSize: 'medium' } }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={showPassword}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    inputProps={{ style: { fontSize: '1.25rem' } }}
                    sx={{
                      backgroundColor: '#9595fb21'
                    }}
                    InputProps={{
                      style: { fontSize: '1.25rem' },
                      sx: {
                        '&:focus': { outline: 'red' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#fff', // Modify the border color when focused
                          borderWidth: 2 // Modify the border width when focused
                        }
                      }
                    }}
                    InputLabelProps={{
                      sx: { '&.Mui-focused': { color: 'white', fontSize: 'medium' } }
                    }}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showPassword}
                      onChange={handleTogglePassword}
                      color="primary"
                      sx={{
                        '&.MuiCheckbox-root': {
                          color: '#535ac8' // Modify the hover color
                        }
                      }}
                    />
                  }
                  label="Soy organizador de eventos"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ color: 'white', backgroundColor: '#535ac8', fontSize: '1rem' }}
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={false}
        onClose={handleClose}
        sx={{
          zIndex: 1001
        }}
        PaperProps={{ sx: { width: '100%', backgroundColor: colors.primary[400], backgroundImage: 'none', zIndex: 10000 } }}
      >
        <DialogTitle
          variant="h2"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          Eventos en curso
          <IconButton onClick={handleClose} color="secondary">
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <a href="https://fabricadenegocio.net" target="_blank">
            <img src={'https://fabricadenegocio.net/assets/img/hero-img.png'} alt="FDN 2023" style={{ width: '100%', height: 'auto' }} />
          </a>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
export default Login;
