import dayjs from 'dayjs';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext, userState } from './AuthContext';

//import 'dayjs/plugin/timezone';
//import utc from 'dayjs/plugin/utc';

//dayjs.extend(utc);
//dayjs.extend(require('dayjs/plugin/timezone'));

type Props = {
  userNotAllowed: boolean;
  children: any;
};
function RequireAuth(props: Props) {
  const location = useLocation();
  const authContext: userState | null = useAuthContext();

  //Verify if authcontext is null
  if (authContext) {
    //Double check for user info
    if (!authContext.user) return <Navigate to="/login" state={{ from: location.pathname }} />;
    else {
      //if now is greater or equal than expiration time
      if (new Date() >= new Date(authContext.user.expiration)) {
        localStorage.clear();
        return <Navigate to="/login" state={{ from: location.pathname }} />;
      }

      if (authContext.user) {
        const role = authContext.user.role;
        if (props.userNotAllowed && role === 'USER') return <Navigate to="/error" />;
      }
    }
  }

  return props.children;
}
export default RequireAuth;
