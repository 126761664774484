import dayjs from 'dayjs';
import * as React from 'react';

import { FormControl, ListItemIcon, ListItemText, MenuItem, TextField, useTheme } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ErrorMessage, Field, Form, Formik, FormikConfig, FormikValues, useFormikContext } from 'formik';
import { Select } from 'formik-mui';
import ReactCountryFlag from 'react-country-flag';
import MultiStepsForm, { FormStep } from '../../components/StepForm/MultiStepsForm';
import { COUNTRIES } from '../../constants/countries';
import { tokens } from '../../theme';

export interface IAppProps {
  isEditing: boolean;
  initialValues: any;
  handleCreate: any;
  editEvent: any;
}

const namesValidation = (values: any) => {
  const errors: { name?: string } = {};
  const name: string = values.name;
  if (!name || name.length === 0) errors.name = 'Nombre invalido';
  return errors;
};

const datesValidation = (values: any) => {
  const errors: { initialDate?: string; endDate?: string } = {};
  const initialDate = values.initialDate;
  const endDate = values.endDate;

  if (!initialDate || initialDate === 'Invalid Date' || !dayjs(initialDate, 'YYYY-MM-DD', true).isValid()) {
    errors.initialDate = 'Fecha incorrecta';
  }

  if (!endDate || endDate === 'Invalid Date' || !dayjs(endDate, 'YYYY-MM-DD', true).isValid()) {
    errors.endDate = 'Fecha incorrecta';
  } else if (dayjs(endDate).isBefore(dayjs(initialDate))) {
    errors.endDate = 'Fecha de finalización no puede ser menor que la de inicio.';
  }

  return errors;
};

const confValidation = (values: any) => {
  const errors: {
    meetingConfiguration?: any;
  } = {};

  const duration = values.meetingConfiguration.meetingDuration;
  const idle = values.meetingConfiguration.meetingIdleTime;
  const notification = values.meetingConfiguration.dailyNotificationTime;

  if (!duration || duration <= 0) {
    errors.meetingConfiguration = { meetingDuration: 'Duración de las citas invalida' };
  }

  if (!idle || idle < 0) {
    errors.meetingConfiguration = { meetingIdleTime: 'Valor invalido' };
  }

  if (!notification || notification === 'Invalid Date' || !dayjs(notification, 'HH:mm').isValid()) {
    errors.meetingConfiguration = { dailyNotificationTime: 'Valor invalido' };
  }

  return errors;
};

function EventForm({ isEditing, initialValues, handleCreate, editEvent }: IAppProps) {
  const [isSubmitting, setisSubmitting] = React.useState(false);
  return (
    <MultiStepsForm
      initialValues={initialValues}
      onSubmit={values => {
        if (isEditing) {
          editEvent(values);
        } else {
          const id = 9999;
          values.id = id;
          handleCreate(values);
          //alert(JSON.stringify(values, null, 2));
        }
      }}
      isSubmitting={isSubmitting}
    >
      <FormStep stepName="Nombre" onSubmit={() => console.log('Step1 submit')} validate={namesValidation}>
        <Field name="name">
          {({ field, form: { touched, errors }, meta }: any) => (
            <>
              <TextField
                {...field}
                fullWidth
                label="Nombre del evento"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </>
          )}
        </Field>
      </FormStep>
      {/**
       * Area de fechas del formulario
       */}
      <FormStep stepName="Fechas" onSubmit={() => console.log('Step2 submit')} validate={datesValidation}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Field name="initialDate">
            {({ field, form, meta }: any) => (
              <>
                <DatePicker
                  {...field}
                  label="Start date"
                  value={dayjs(field.value)}
                  format={'YYYY-MM-DD'}
                  onChange={(date: Date) => form.setFieldValue('initialDate', dayjs(date).format('YYYY-MM-DD'))}
                  renderInput={(params: any) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                      />
                    </>
                  )}
                  className="full-width"
                />
                {meta.touched && meta.error && <ErrorMessage name="initialDate" component="p" className="form-error-label" />}
              </>
            )}
          </Field>
          <Field name="endDate" label="Fecha de finalización">
            {({ field, form, meta }: any) => (
              <>
                <DatePicker
                  {...field}
                  label="End date"
                  value={dayjs(field.value)}
                  format={'YYYY-MM-DD'}
                  onChange={(date: Date) => form.setFieldValue('endDate', dayjs(date).format('YYYY-MM-DD'))}
                  renderInput={(params: any) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                      />
                    </>
                  )}
                  className="full-width"
                  sx={{ marginTop: '2em' }}
                />
                {meta.touched && meta.error && <ErrorMessage name="endDate" component="p" className="form-error-label" />}
              </>
            )}
          </Field>
        </LocalizationProvider>
      </FormStep>
      {/**
       * Area de participantes del formulario
       */}
      <FormStep stepName="Participantes" onSubmit={() => console.log('Step3 submit')}>
        <FormControl style={{ width: '100%' }}>
          <Field
            name="countries"
            label="Paises participantes"
            component={Select}
            multiple={true}
            renderValue={(selected: any) => selected.join(', ')}
          >
            {COUNTRIES.map((country: any) => {
              return (
                <MenuItem value={country.alpha2Code} key={country.alpha2Code}>
                  <ListItemIcon>
                    <ReactCountryFlag countryCode={country.alpha2Code} title={country.name['es']} />
                  </ListItemIcon>
                  <ListItemText>{country.name['es']}</ListItemText>
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
      </FormStep>
      {/**
       * Area de configuraciones del formulario
       */}
      <FormStep stepName="Configuraciones" onSubmit={() => console.log('Step4 submit')} validate={confValidation}>
        <Field name="meetingConfiguration.meetingDuration" label="Duración de las reuniones (min)">
          {({ field, meta }: any) => (
            <>
              <TextField
                {...field}
                label="Duración de las reuniones (min)"
                type="number"
                fullWidth
                inputProps={{
                  maxLength: 13,
                  step: '1'
                }}
              />
              {meta.touched && meta.error && (
                <ErrorMessage name="meetingConfiguration.meetingDuration" component="p" className="form-error-label" />
              )}
            </>
          )}
        </Field>
        <Field name="meetingConfiguration.meetingIdleTime" label="Duración entre reuniones (IDLE)">
          {({ field, meta }: any) => (
            <>
              <TextField
                {...field}
                type="number"
                fullWidth
                label="Duración entre reuniones (IDLE)"
                inputProps={{
                  maxLength: 13,
                  step: '1'
                }}
                sx={{ marginTop: '2em', marginBottom: '2em' }}
              />
              {meta.touched && meta.error && (
                <ErrorMessage name="meetingConfiguration.meetingIdleTime" component="p" className="form-error-label" />
              )}
            </>
          )}
        </Field>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Field name="meetingConfiguration.dailyNotificationTime" label="Hora de notificación">
            {({ field, form, meta }: any) => (
              <>
                <TimePicker
                  {...field}
                  format={'HH:mm'}
                  onChange={(date: Date) => form.setFieldValue('meetingConfiguration.dailyNotificationTime', dayjs(date).format('HH:mm'))}
                  className="full-width"
                  ampm={false}
                  value={dayjs(field.value, 'HH:mm')}
                  label="Hora de notificación"
                  renderInput={(params: any) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                      />
                    </>
                  )}
                />
                {meta.touched && meta.error && (
                  <ErrorMessage name="meetingConfiguration.dailyNotificationTime" component="p" className="form-error-label" />
                )}
              </>
            )}
          </Field>
        </LocalizationProvider>
      </FormStep>
    </MultiStepsForm>
  );
}

export default EventForm;
