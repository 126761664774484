import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './useAuth';

export interface userState {
  user: any;
  login: any;
  logout: any;
}

const AuthContext = createContext<userState | null>(null);
const useAuthContext = () => useContext(AuthContext);

const AuthProvider = (props: any) => {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};

export { AuthProvider, useAuthContext };
