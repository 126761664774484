import { Button, Grid } from '@mui/material';
import { FormikValues } from 'formik';
import React from 'react';

interface Props {
  hasPrevious: boolean;
  onBackClick: (values: FormikValues) => void;
  isLastStep: boolean;
  isSubmitting: boolean;
}

const FormNavigation = (props: Props) => {
  return (
    <Grid container spacing={2} justifyContent={'center'} marginTop={2}>
      {props.hasPrevious && (
        <Grid item>
          <Button variant="contained" type="button" onClick={props.onBackClick} disabled={props.isSubmitting}>
            Atras
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button type="submit" color="primary" variant="contained" disabled={props.isSubmitting}>
          {props.isLastStep ? 'Guardar' : 'Siguiente'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormNavigation;
