import React, { createContext, useContext, useState } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import AppSidebar from '../pages/global/AppSidebar';
const SidebarContext = createContext<any>({});

export const SidebarProvider: React.FC<any> = (props: any) => {
  const [sidebarBackgroundColor, setSidebarBackgroundColor] = useState(undefined);
  const [sidebarImage, setSidebarImage] = useState(undefined);
  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,

          sidebarImage,
          setSidebarImage
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <AppSidebar />
          {props.children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};
export const useSidebarContext = () => useContext(SidebarContext);
