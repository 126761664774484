import { Step, StepLabel, Stepper, useTheme } from '@mui/material';
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { tokens } from '../../theme';
import FormNavigation from './FormNavigation';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  isSubmitting: boolean;
}
const MultiStepsForm = ({ children, initialValues, onSubmit, isSubmitting }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children) as React.ReactElement[];

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const [snapShot, setsnapShot] = useState(initialValues);

  const next = (values: FormikValues) => {
    setsnapShot(values);
    setStepNumber(stepNumber + 1);
  };

  const previous = (values: FormikValues) => {
    setsnapShot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  return (
    <div>
      <Formik
        initialValues={snapShot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        validate={step.props.validate}
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Stepper
              alternativeLabel
              activeStep={stepNumber}
              sx={{
                marginY: '20px',
                '& .MuiStepLabel-label': {
                  fontSize: '1rem' // Adjust the font size if needed
                },
                '& .MuiStepIcon-root': {
                  width: '2.5rem', // Adjust the step icon size
                  height: '2rem'
                }
              }}
            >
              {steps.map(currentStep => {
                const label = currentStep.props.stepName;
                return (
                  <Step
                    key={label}
                    sx={{
                      '.css-oxf95d-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
                        color: colors.greenAccent[500]
                      }
                    }}
                    style={{
                      fontSize: 'medium'
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {step}
            <FormNavigation
              isLastStep={isLastStep}
              hasPrevious={stepNumber > 0}
              onBackClick={() => {
                previous(formik.values);
              }}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepsForm;

export const FormStep = ({ stepName = '', children }: any) => children;
