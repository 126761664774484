import { Box } from '@mui/system';
import React, { ReactNode } from 'react';
import { Header } from '../../components/header/Header';

type Props = {
  title: string;
  subtitle: string;
  instructions?: string;
  children: ReactNode;
};
const Content: React.FC<any> = ({ title, subtitle, instructions, children }: Props) => {
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} instructions={instructions || ''} />
      </Box>
      {children}
    </Box>
  );
};
export default Content;
