import { Box, IconButton, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { ColorModeContext, tokens } from '../../theme';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useAuthContext } from '../../contexts/auth/AuthContext';

export interface IAppProps {}

function App(props: IAppProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const authContext = useAuthContext();

  const handleLogout = () => {
    if (authContext) authContext.logout();
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
        {broken && (
          <IconButton sx={{ margin: '0 6 0 2' }} onClick={() => toggleSidebar()}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
        <Box display="flex" color={colors.primary[400]} p={0.2} borderRadius={1}></Box>
      </Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
        </IconButton>
        <IconButton onClick={handleLogout}>
          <LoginOutlinedIcon />
        </IconButton>
        {broken && (
          <IconButton sx={{ margin: '0 6 0 2' }} onClick={() => toggleSidebar()}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default App;
