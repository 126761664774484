import * as React from 'react';

import { SidebarProvider } from '../../contexts/SidebarContext';
import Topbar from './Topbar';

type Props = {
  children: any;
};
function MainWrapper({ children }: Props) {
  return (
    <SidebarProvider>
      <div style={{ height: '100%', width: '100%' }}>
        <main>
          <Topbar />
          {children}
        </main>
      </div>
    </SidebarProvider>
  );
}
export default MainWrapper;
