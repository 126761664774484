import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4'
  },
  sectiontitle: { color: '#000', textAlign: 'center', marginTop: 30, marginRight: 30, marginBottom: 10, marginLeft: 30 },
  entityheading: { fontSize: 26, textAlign: 'center', marginBottom: 0 },
  sectiondetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30
  },
  entitydetails: { fontSize: 14, textAlign: 'left', marginBottom: 10 },
  section: {
    marginLeft: 30,
    marginRight: 30,
    flexGrow: 1,
    textAlign: 'center'
  },
  table: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    borderTop: 'none',
    backgroundColor: '#808080'
  },
  bold: {
    fontWeight: 'bold'
  },
  col1: {
    width: '40%',
    fontSize: 10
  },
  col2: {
    width: '25%',
    fontSize: 10
  },
  col3: {
    width: '15%',
    fontSize: 10
  },
  col4: {
    width: '40%',
    fontSize: 10,
    flexWrap: 'wrap'
  }
});

interface props {
  meetings: any;
  name: string;
}

function PDFMeetings({ meetings, name }: props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.sectiontitle}>
          <Text style={styles.entityheading}>Resumen de citas</Text>
        </View>
        <View style={styles.sectiondetails}>
          <Text style={styles.entitydetails}>Estimado (a): {name}</Text>
          <Text style={styles.entitydetails}>A continuación se detallan sus citas agendadas</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <View style={styles.col1}>
                <Text>Nombre</Text>
              </View>
              <View style={styles.col2}>
                <Text>Fecha</Text>
              </View>
              <View style={styles.col3}>
                <Text>Inicio</Text>
              </View>
              <View style={styles.col4}>
                <Text>Observaciones</Text>
              </View>
            </View>
            {meetings.map((row: any, i: any) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.col1}>
                  <Text style={styles.bold}>{row.name}</Text>
                </Text>
                <Text style={styles.col2}>{row.schedule.date}</Text>
                <Text style={styles.col3}>{row.schedule.initialHour}</Text>
                <Text style={styles.col4}>
                  <Text style={styles.bold}>{row.schedule.host}</Text>
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFMeetings;
