import axios from 'axios';
import { _constants } from '../../constants/values';

export class userServices {
  /**
   *
   * @param token auth token
   * @param data new user info
   * @returns status code and error/success message
   */
  public async createUser(token: string, data: any): Promise<any> {
    const response = await axios.post(`${_constants.API_HOST}/user`, data, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  /**
   *
   * @param token auth token
   * @param data new user info
   * @returns status code and error/success message
   */
  public async updateUser(token: string, data: any): Promise<any> {
    const response = await axios.put(`${_constants.API_HOST}/user`, data, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }
}
