import * as React from 'react';

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';

import { COUNTRIES } from '../../constants/countries';

import { Label } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useTheme
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ErrorMessage, Field } from 'formik';
import ReactCountryFlag from 'react-country-flag';
import MultiStepsForm, { FormStep } from '../../components/StepForm/MultiStepsForm';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { UserResponse } from '../../models/common';
import { contactService } from '../../services/contact/contact';
import { tokens } from '../../theme';
import MainWrapper from '../global/MainWrapper';

export interface IAppProps {
  isEditing: boolean;
  initialValues: any;
  handleCreate: any;
  currentGuestList?: any;
  editUser: any;
}

const infoValidation = (values: any) => {
  const errors: { id?: string; name?: string; lastName?: string; telephone?: string } = {};
  const id: string = values.id;
  if (!id || id.length === 0) errors.id = 'Email invalido';

  const _name: string = values.name;
  if (!_name || _name.length === 0) errors.name = 'Nombre invalido';

  const _lastname: string = values.lastName;
  if (!_lastname || _lastname.length === 0) errors.lastName = 'Apellido invalido';

  const tel: string = values.telephone;
  if (!tel || tel.length === 0) errors.telephone = 'Teléfono invalido';

  return errors;
};

const companyInfoValidator = (values: any) => {
  const errors: { companyName?: string; tin?: string; position?: string } = {};
  const company: string = values.companyName;
  if (!company || company.length === 0) errors.companyName = 'Nombre de compañia invalido';

  const nit: string = values.tin;
  if (!nit || nit.length === 0) errors.tin = 'Identificador tributario invalido';

  const _position: string = values.position;
  if (!_position || _position.length === 0) errors.position = 'Posición inválida';

  return errors;
};

const accountDetailValidator = (values: any) => {
  const errors: { password?: string } = {};
  const pass: string = values.password;
  if (!pass || pass.length === 0) errors.password = 'Contraseña invalida';
  return errors;
};

function MeetingForm({ isEditing, initialValues, handleCreate, editUser }: IAppProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSubmitting, setisSubmitting] = React.useState(false); //State to enable/disable button when form is submitting
  const [contactList, setcontactList]: any = React.useState([]);
  const [rowSelection, setrowSelection] = React.useState([]);

  return (
    <MultiStepsForm
      initialValues={initialValues}
      onSubmit={values => {
        if (isEditing) {
          editUser(values);
        } else {
          handleCreate(values);
        }
      }}
      isSubmitting={isSubmitting}
    >
      {/**
       * Contact basic info
       */}
      <FormStep stepName="Informacion basica" onSubmit={() => console.log('Step 1 submit')} validate={infoValidation}>
        <Field name="id">
          {({ field, form, meta }: any) => (
            <>
              <TextField {...field} fullWidth label="Email" type="email" />
              {meta.touched && meta.error && <ErrorMessage name="id" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
        <Field name="name">
          {({ field, form, meta }: any) => (
            <>
              <TextField {...field} fullWidth label="Nombre" sx={{ marginTop: '2em' }} />
              {meta.touched && meta.error && <ErrorMessage name="name" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
        <Field name="lastName">
          {({ field, form, meta }: any) => (
            <>
              <TextField {...field} fullWidth label="Apellido" sx={{ marginTop: '2em' }} />
              {meta.touched && meta.error && <ErrorMessage name="lastName" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
        <Field name="telephone">
          {({ field, form, meta }: any) => (
            <>
              <TextField {...field} fullWidth label="Teléfono" sx={{ marginTop: '2em' }} />
              {meta.touched && meta.error && <ErrorMessage name="telephone" component="p" className="form-error-label" />}
            </>
          )}
        </Field>

        <FormControl style={{ width: '100%' }}>
          <Field name="country">
            {({ field, form, meta }: any) => (
              <Select
                {...field}
                label="País de origen"
                multiple={false}
                sx={{ marginTop: '2em' }}
                onChange={(event: any) => {
                  // Manually update Formik's state when the select value changes
                  form.setFieldValue('country', event.target.value);
                }}
              >
                {COUNTRIES.map((country: any) => {
                  return (
                    <MenuItem value={country.alpha2Code} key={country.alpha2Code}>
                      <ListItemText>
                        <ReactCountryFlag countryCode={country.alpha2Code} title={country.name['es']} /> {country.name['es']}
                      </ListItemText>
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Field>
        </FormControl>
      </FormStep>
      {/**
       * Company info
       */}
      <FormStep stepName="Informacion de la empresa" onSubmit={() => console.log('Step 2')} validate={companyInfoValidator}>
        <Field name="companyName">
          {({ field, form, meta }: any) => (
            <>
              <TextField {...field} fullWidth label="Nombre de la empresa" />
              {meta.touched && meta.error && <ErrorMessage name="companyName" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
        <Field name="tin">
          {({ field, form: { touched, errors }, meta }: any) => (
            <>
              <TextField
                {...field}
                fullWidth
                label="Id Fiscal"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{ marginTop: '2em' }}
              />
              {meta.touched && meta.error && <ErrorMessage name="tin" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
        <Field name="position">
          {({ field, form: { touched, errors }, meta }: any) => (
            <>
              <TextField
                {...field}
                fullWidth
                label="Cargo"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{ marginTop: '2em' }}
              />
              {meta.touched && meta.error && <ErrorMessage name="position" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
      </FormStep>
      {/**
       * Account details
       */}
      <FormStep stepName="Informacion de la cuenta" onSubmit={() => console.log('Step 3 submit')} validate={accountDetailValidator}>
        <Field name="enable">
          {({ field, form, meta }: any) => <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label="Habilitado" />}
        </Field>

        <FormControl style={{ width: '100%' }}>
          <Field name="idRole" label="Rol dentro de la plataforma" sx={{ marginTop: '2em' }}>
            {({ field, form, meta }: any) => (
              <Select
                {...field}
                label="Rol en la plataforma"
                multiple={false}
                sx={{ marginTop: '2em' }}
                onChange={(event: any) => {
                  // Manually update Formik's state when the select value changes
                  form.setFieldValue('idRole', event.target.value);
                }}
              >
                <MenuItem value={'ADMIN'} key={'ADMIN'}>
                  Administrador
                </MenuItem>
                <MenuItem value={'USER'} key={'USER'}>
                  Usuario
                </MenuItem>
                <MenuItem value={'CHAINUSER'} key={'CHAINUSER'}>
                  Invitado Cadena
                </MenuItem>
                <MenuItem value={'VISUALIZATION'} key={'VISUALIZATION'}>
                  Visualizador
                </MenuItem>
              </Select>
            )}
          </Field>
        </FormControl>
        <Field name="password">
          {({ field, form, meta }: any) => (
            <>
              <TextField {...field} type="password" fullWidth label="Contraseña" sx={{ marginTop: '2em' }} />
              {meta.touched && meta.error && <ErrorMessage name="password" component="p" className="form-error-label" />}
            </>
          )}
        </Field>
      </FormStep>
    </MultiStepsForm>
  );
}

export default MeetingForm;
