import { Box, Theme, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { tokens } from '../../theme';

export interface IAppProps {
  title: String;
  subtitle: String;
  instructions: string;
}

export function Header(props: IAppProps) {
  const theme: Theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ mb: '5px' }}>
        {props.title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {props.subtitle}
      </Typography>
      {props.instructions && (
        <Typography variant="h5" color={colors.primary[200]}>
          <br />
          {props.instructions}
        </Typography>
      )}
    </Box>
  );
}
