import * as React from 'react';

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';

import { Box, FormControl, ListItemIcon, ListItemText, MenuItem, Select, TextField, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ErrorMessage, Field } from 'formik';
import MultiStepsForm, { FormStep } from '../../components/StepForm/MultiStepsForm';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { UserResponse } from '../../models/common';
import { contactService } from '../../services/contact/contact';
import { tokens } from '../../theme';
import MainWrapper from '../global/MainWrapper';

const guests_columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Nombre'
  },
  { field: 'email', headerName: 'Email' },
  { field: 'idFiscal', headerName: 'idFiscal' },
  { field: 'Empresa', headerName: 'Empresa' },
  { field: 'Puesto', headerName: 'Puesto' },
  { field: 'Tel', headerName: 'Tel' }
];

export interface IAppProps {
  isEditing: boolean;
  initialValues: any;
  handleCreate: any;
  currentGuestList?: any;
  editMeeting: any;
}

const namesValidation = (values: any) => {
  const errors: { name?: string } = {};
  const name: string = values.name;
  if (!name || name.length === 0) errors.name = 'Nombre invalido';
  return errors;
};

function MeetingForm({ isEditing, initialValues, handleCreate, editMeeting }: IAppProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSubmitting, setisSubmitting] = React.useState(false); //State to enable/disable button when form is submitting
  const [contactList, setcontactList]: any = React.useState([]);
  const [rowSelection, setrowSelection] = React.useState([]);

  const authContext = useAuthContext();

  React.useEffect(() => {
    /**
     * Steps:
     * 1. Get the contact list from the company
     * 2. Verify if the current guest list is null or empty
     * 3. If current list is not empty we need to get rows from current
     */

    const test = [
      { name: 'Invitado SV', email: 'invitadoSV123@gmail.com' },
      { name: 'Ana Infante', email: 'ainfante@gmail.com' },
      { name: 'Pedro Infante', email: 'pinfante1@gmail.com' },
      { name: 'Invitado GT', email: 'invitadoGT@gmail.com' },
      { name: 'Invitado HN', email: 'invitadoHN@gmail.com' },
      { name: 'Invitado CR', email: 'invitadoCR@gmail.com' },
      { name: 'Invitado SV', email: 'invitadoSV@gmail.com' },
      { name: 'Ana Infante', email: 'ainfante11@gmail.com' },
      { name: 'Pedro Infante', email: 'pinfante11@gmail.com' },
      { name: 'Invitado GT', email: 'invitadoGT11@gmail.com' },
      { name: 'Invitado HN', email: 'invitadoHN1@gmail.com' },
      { name: 'Invitado CR', email: 'invitadoCR1@gmail.com' },
      { name: 'Invitado SV', email: 'invitadoSV2@gmail.com' },
      { name: 'Ana Infante', email: 'ainfante2@gmail.com' },
      { name: 'Pedro Infante', email: 'pinfante12@gmail.com' },
      { name: 'Invitado GT', email: 'invitadoGT2@gmail.com' },
      { name: 'Invitado HN', email: 'invitadoHN2@gmail.com' },
      { name: 'Invitado CR', email: 'invitadoCR2@gmail.com' }
    ];

    const fetchData = async () => {
      try {
        if (authContext) {
          const service = new contactService();
          const response = (await service.getUsersForMeeting(authContext.user.token)) as UserResponse;
          //Change the attributes that need the UI

          setcontactList(
            response.users.map(e => {
              return {
                name: `${e.name} ${e.lastName}`,
                email: e.email,
                idFiscal: e.tin,
                Empresa: e.companyName,
                Puesto: e.position,
                Tel: e.telephone
              };
            })
          );
        }
        //setRows(example_rows);
      } catch (error) {
        setcontactList(test);
      }
    };
    fetchData();
    setrowSelection(initialValues.guests);
  }, []);

  return (
    <MultiStepsForm
      initialValues={initialValues}
      onSubmit={values => {
        console.log(values);

        values.guests = !rowSelection ? [] : rowSelection;
        if (isEditing) {
          editMeeting(values);
          console.log(values);
          //alert(JSON.stringify(values, null, 2));
        } else {
          //const id = 9999;
          //values.id = id;
          handleCreate(values);
          //alert(JSON.stringify(values, null, 2));
        }
      }}
      isSubmitting={isSubmitting}
    >
      {/**
       * Meeting name
       */}
      <FormStep stepName="Nombre de la cita" onSubmit={() => console.log('Step 1 submit')} validate={namesValidation}>
        <Field name="name">
          {({ field, form: { touched, errors }, meta }: any) => (
            <>
              <TextField
                {...field}
                fullWidth
                label="Nombre de la cita"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </>
          )}
        </Field>
      </FormStep>
      {/**
       * Meeting format
       */}
      <FormStep stepName="Formato y ubicación" onSubmit={() => console.log('Step 2')}>
        <Field name="schedule.format">
          {({ field, form, meta }: any) => (
            <Select
              {...field}
              label="Formato"
              fullWidth
              multiple={false}
              onChange={(e: any) => {
                form.setFieldValue('schedule.format', e.target.value);
              }}
            >
              <MenuItem value={0} key={0}>
                <ListItemIcon>
                  <DevicesOutlinedIcon />
                </ListItemIcon>{' '}
                Físico
              </MenuItem>
              <MenuItem value={1} key={1}>
                <ListItemIcon>
                  <BusinessOutlinedIcon />
                </ListItemIcon>{' '}
                Virtual
              </MenuItem>
            </Select>
          )}
        </Field>
        <Field name="schedule.host">
          {({ field, form: { touched, errors }, meta }: any) => (
            <>
              <TextField
                {...field}
                fullWidth
                label="Dirección o URL de cita"
                error={Boolean(touched.host && errors.host)}
                helperText={touched.host && errors.host}
                sx={{ marginTop: '2em' }}
              />
            </>
          )}
        </Field>
      </FormStep>
      {/**
       * Meeting times
       */}
      <FormStep stepName="Fecha y hora" onSubmit={() => console.log('Step 3 submit')}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Field name="schedule.date">
            {({ field, form, meta }: any) => (
              <>
                <DatePicker
                  {...field}
                  label="Meeting date"
                  value={dayjs(field.value)}
                  format={'YYYY-MM-DD'}
                  onChange={(date: Date) => form.setFieldValue('schedule.date', dayjs(date).format('YYYY-MM-DD'))}
                  renderInput={(params: any) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                        sx={{ marginBottom: '2em' }}
                      />
                    </>
                  )}
                  className="full-width"
                />
                {meta.touched && meta.error && <ErrorMessage name="schedule.date" component="p" className="form-error-label" />}
              </>
            )}
          </Field>
          <Field name="schedule.initialHour">
            {({ field, form, meta }: any) => (
              <>
                <TimePicker
                  {...field}
                  format={'HH:mm'}
                  onChange={(date: Date) => form.setFieldValue('schedule.initialHour', dayjs(date).format('HH:mm'))}
                  className="full-width"
                  ampm={false}
                  value={dayjs(field.value, 'HH:mm')}
                  label="Starting hour"
                  sx={{ marginTop: '2em' }}
                  renderInput={(params: any) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                      />
                    </>
                  )}
                />
                {meta.touched && meta.error && <ErrorMessage name="schedule.initialHour" component="p" className="form-error-label" />}
              </>
            )}
          </Field>
          <Field name="schedule.endHour">
            {({ field, form, meta }: any) => (
              <>
                <TimePicker
                  {...field}
                  format={'HH:mm'}
                  onChange={(date: Date) => form.setFieldValue('schedule.endHour', dayjs(date).format('HH:mm'))}
                  className="full-width"
                  ampm={false}
                  value={dayjs(field.value, 'HH:mm')}
                  label="End hour"
                  sx={{ marginTop: '2em' }}
                  renderInput={(params: any) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                      />
                    </>
                  )}
                />
                {meta.touched && meta.error && <ErrorMessage name="schedule.endHour" component="p" className="form-error-label" />}
              </>
            )}
          </Field>
        </LocalizationProvider>
      </FormStep>
      {/**
       * Meeting guests
       */}
      <FormStep stepName="Invitados" onSubmit={() => console.log('Step 4 submit')}>
        <Box
          m="8px 0 0 0"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300]
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400]
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700]
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`
            },
            overflow: 'auto'
          }}
          height={'50vh'}
        >
          <DataGrid
            getRowId={row => row.email}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            columns={guests_columns}
            rows={contactList}
            onRowSelectionModelChange={(newRowSelectionModel: any) => {
              setrowSelection(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelection}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
          />
        </Box>
      </FormStep>
    </MultiStepsForm>
  );
}

export default MeetingForm;
