import { CloseOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, useTheme } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import * as React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Header } from '../../components/header/Header';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { Guest, MeetingResponse, UserResponse } from '../../models/common';
import { contactService } from '../../services/contact/contact';
import { eventServices } from '../../services/events/events';
import { meetingServices } from '../../services/meetings/meetings';
import { userServices } from '../../services/user/user';
import { tokens } from '../../theme';
import Content from '../global/Content';
import MainWrapper from '../global/MainWrapper';
import MeetingForm from './userForm';

const example_rows: any = [
  {
    id: 'ME001',
    name: 'Cita con los pedros',
    eventId: 'PR001',
    schedule: {
      date: '2023-06-20',
      initialHour: '15:00',
      endHour: '15:30',
      format: 0, //Fisico
      host: 'Blvd. Los Próceres 24-69 Z-10'
    },
    guests: ['gerardo@correo.com', 'daniel@correo.com', 'gerardo@correo.com']
  },
  {
    id: 'ME002',
    name: 'Cita con los juanes',
    eventId: 'PR001',
    schedule: {
      date: '2023-06-20',
      initialHour: '15:30',
      endHour: '16:00',
      format: 1, //Digital
      host: 'https://meet.google.com/'
    },
    guests: [
      'gerardo@correo.com',
      'daniel@correo.com',
      'gerardo@correo.com',
      'gerardo@correo.com',
      'daniel@correo.com',
      'gerardo@correo.com'
    ]
  }
];

type Props = {};
function Users({}: Props) {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [modalOpen, setmodalOpen] = React.useState<boolean>(false);
  const [isEditing, setisEditing] = React.useState<boolean>(false); //Var to define if the modal form opens in edit mode
  const [initialData, setinitialData] = React.useState<any>(null);
  const [rows, setRows] = React.useState<any>([]);

  const authContext = useAuthContext();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'email', flex: 0.1 },
    {
      field: 'name',
      headerName: 'Nombre',
      hideable: true
    },
    {
      field: 'lastName',
      headerName: 'Apellido',
      hideable: true
    },
    {
      field: 'fullName',
      headerName: 'Nombre',
      cellClassName: 'name-column--cell',
      flex: 0.2
    },
    {
      field: 'companyName',
      headerName: 'Empresa',
      flex: 0.2
    },
    {
      field: 'tin',
      headerName: 'Id Fiscal',
      flex: 0.1
    },
    {
      field: 'enable',
      headerName: 'Activo',
      type: 'boolean',
      flex: 0.1
    },
    {
      field: 'country',
      headerName: 'Pais',
      flex: 0.1,
      renderCell: ({ row: { country } }) => {
        return (
          <>
            <ReactCountryFlag key={country} countryCode={country} title={country} />
          </>
        );
      }
    },
    {
      field: 'telephone',
      headerName: 'Telefono',
      flex: 0.1
    },
    {
      field: 'position',
      headerName: 'Cargo',
      flex: 0.1
    },
    {
      field: 'idRole',
      headerName: 'Rol',
      hideable: true
    },
    {
      field: 'password',
      headerName: 'Contraseña',
      hideable: true
    },
    {
      field: '',
      sortable: false,
      renderCell: (params: any) => (
        <>
          <IconButton onClick={() => handleEdit(params)}>
            <EditOutlinedIcon />
          </IconButton>
        </>
      )
    }
  ];

  React.useEffect(() => {
    //From here I'll do fetch from the api to retrieve all events from db for current user
    const fetchData = async () => {
      try {
        if (authContext) {
          const service = new contactService();
          const response = (await service.getUsers(authContext.user.token)) as UserResponse;
          //Change the attributes that need the UI
          console.log(response);
          setRows(
            response.users.map(e => {
              return {
                name: e.name,
                lastName: e.lastName,
                fullName: `${e.name} ${e.lastName}`,
                id: e.email,
                companyName: e.companyName,
                tin: e.tin,
                enable: e.enable,
                country: e.country,
                telephone: e.telephone,
                position: e.position,
                idRole: e.idRole
              };
            })
          );
        }
        //setRows(example_rows);
      } catch (error) {
        setRows([]);
      }
    };

    fetchData();
    //setRows(rowsExample);
  }, [modalOpen]);

  /**
   * Method to handle the close event from the modal form
   * @param event event triggered on the dialog
   * @param reason reason that closes the dialog
   * @returns void
   */
  const handleClose = (event: any, reason: any) => {
    //Avoid to close the modal when click outside
    if (reason === 'backdropClick') {
      event.stopPropagation();
      return;
    }

    setmodalOpen(false);
  };

  /**
   * Method to handle the open button for the modal form
   */
  const handleOpen = () => {
    setisEditing(false);
    resetFormValues();
    setmodalOpen(true); //Open modal form
  };

  const handleCreate = async (newItem: any) => {
    const _userServices = new userServices();
    if (authContext) {
      let fixedJson = { ...newItem };
      fixedJson.email = fixedJson.id;
      delete fixedJson['id'];
      console.log(fixedJson);
      try {
        let response = await _userServices.createUser(authContext.user.token, {
          type: 'user',
          input: fixedJson
        });
        Swal.fire({
          title: 'Info!',
          text: `${response}`,
          icon: 'info'
        });
        setRows([...rows, newItem]); //Add new label to state array
        resetFormValues(); //Reset values
      } catch (error) {}

      //setRows(response.meetings);

      setmodalOpen(false); //Close modal
    }
  };

  /**
   * Method to handle the edit button in each row, only open the form in edit mode
   * @param params contains all the information from the clicked row
   */
  const handleEdit = (params: GridCellParams) => {
    setinitialData({
      id: params.row.id,
      name: params.row.name,
      lastName: params.row.lastName,
      password: params.row.password,
      companyName: params.row.companyName,
      tin: params.row.tin,
      enable: true,
      country: params.row.country,
      telephone: params.row.telephone,
      position: params.row.position,
      idRole: params.row.idRole,
      events: params.row.events
    });
    setisEditing(true); //Set form in edit mode
    setmodalOpen(true); //Open modal form
  };

  /**
   *
   * @param updatedItem functions that calls api to update event
   */
  const editUser = async (updatedItem: any) => {
    const service = new userServices();
    let fixedJson = { ...updatedItem };
    fixedJson.email = fixedJson.id;
    fixedJson.events = [];
    if (authContext) {
      try {
        const response = await service.updateUser(authContext.user.token, {
          type: 'user',
          input: { ...fixedJson }
        });

        Swal.fire({
          title: 'Yayy!',
          text: `${response}`,
          icon: 'success'
        });
        resetFormValues(); //Reset values
        setmodalOpen(false); //Close modal
      } catch (error) {
        console.log(error);

        const response = error as AxiosError;
        const message = response.response!.data as any;
        Swal.fire({
          title: 'Error!',
          text: `${message}`,
          icon: 'error'
        });
      }
    }
  };

  const resetFormValues = () => {
    setinitialData({
      id: '',
      name: '',
      lastName: '',
      password: '',
      companyName: '',
      tin: '',
      enable: true,
      country: 'GT',
      telephone: '',
      position: '',
      idRole: 'USER',
      events: []
    });
  };

  return (
    <MainWrapper>
      <Content title={'Contactos'} subtitle={'ABC Contactos'}>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button color="secondary" variant="contained" onClick={handleOpen}>
            Crear contacto
          </Button>
        </Box>
        <Box
          m="8px 0 0 0"
          height="80vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300]
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400]
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700]
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`
            }
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              name: false,
              lastName: false,
              idRole: false,
              password: false
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
          />
        </Box>
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          maxWidth="md"
          sx={{
            zIndex: 1001
          }}
          PaperProps={{ sx: { width: '100%', backgroundColor: colors.primary[400], zIndex: 10000 } }}
        >
          <DialogTitle
            variant="h2"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Contacto
            <IconButton onClick={() => setmodalOpen(false)} color="primary">
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: 'fit-content', overflow: 'hidden' }}>
            <MeetingForm isEditing={isEditing} initialValues={initialData} handleCreate={handleCreate} editUser={editUser} />
          </DialogContent>
        </Dialog>
      </Content>
    </MainWrapper>
  );
}
export default Users;
