export const COUNTRIES: any = [
  {
    name: {
      en: 'United States',
      es: 'Estados Unidos'
    },
    alpha2Code: 'US'
  },
  {
    name: {
      en: 'Canada',
      es: 'Canadá'
    },
    alpha2Code: 'CA'
  },
  {
    name: {
      en: 'Mexico',
      es: 'México'
    },
    alpha2Code: 'MX'
  },
  {
    name: {
      en: 'Belize',
      es: 'Belice'
    },
    alpha2Code: 'BZ'
  },
  {
    name: {
      en: 'Costa Rica',
      es: 'Costa Rica'
    },
    alpha2Code: 'CR'
  },
  {
    name: {
      en: 'El Salvador',
      es: 'El Salvador'
    },
    alpha2Code: 'SV'
  },
  {
    name: {
      en: 'Guatemala',
      es: 'Guatemala'
    },
    alpha2Code: 'GT'
  },
  {
    name: {
      en: 'Honduras',
      es: 'Honduras'
    },
    alpha2Code: 'HN'
  },
  {
    name: {
      en: 'Nicaragua',
      es: 'Nicaragua'
    },
    alpha2Code: 'NI'
  },
  {
    name: {
      en: 'Panama',
      es: 'Panamá'
    },
    alpha2Code: 'PA'
  }
];
