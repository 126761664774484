import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Events from './pages/events';

import { useAuthContext } from './contexts/auth/AuthContext';
import RequireAuth from './contexts/auth/RequireAuth';
import ErrorComp from './pages/error/error';
import Topbar from './pages/global/Topbar';
import Home from './pages/home';
import Login from './pages/login';
import Meetings from './pages/meetings';
import Users from './pages/users';
import { ColorModeContext, useMode } from './theme';

const theme = createTheme(); // Create a default theme

const App: React.FC = () => {
  const [theme, colorMode] = useMode();
  const authContext = useAuthContext();

  // Filter the route configuration based on the user's role
  //const filteredRoutes = routeConfig.filter(route => !route.allowedRoles || route.allowedRoles.includes(userRole));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<ErrorComp />} />

          <Route
            path="/"
            element={
              <RequireAuth userNotAllowed={false}>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/events"
            element={
              <RequireAuth userNotAllowed={true}>
                <Events />
              </RequireAuth>
            }
          />
          <Route
            path="/events/:id/meetings"
            element={
              <RequireAuth userNotAllowed={true}>
                <Meetings />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth userNotAllowed={true}>
                <Users />
              </RequireAuth>
            }
          />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
