import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CloseOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Import the Spanish locale
import React, { ReactDOM, useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { Meeting, MeetingResponse } from '../../models/common';
import Content from '../../pages/global/Content';
import MainWrapper from '../../pages/global/MainWrapper';
import { meetingServices } from '../../services/meetings/meetings';
import { tokens } from '../../theme';

import './calendarStyle.css';

const generateRandomColor = (): string => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};

async function getData(token: string, email: string): Promise<Array<Meeting> | undefined> {
  try {
    const service = new meetingServices();
    const response = (await service.getMeetings(token, email)) as MeetingResponse;
    return response.meetings;
  } catch (error) {
    console.error(error);
    return [];
  }
}

type Props = {};
interface eventSelected {
  title: string;
  date: string;
  start: string;
  end: string;
  format: string;
  host: string;
}
function CalendarView({}: Props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [meetings, setMeetings] = useState<any[]>([]);
  const authContext = useAuthContext();
  const [modalOpen, setmodalOpen] = useState(false);
  const [eventSelected, seteventSelected] = useState<eventSelected>({
    title: '',
    start: '',
    date: '',
    end: '',
    format: '',
    host: ''
  });

  useEffect(() => {
    const _getMeetings = async () => {
      if (authContext) {
        const data = await getData(authContext.user.token, authContext.user.email).then(result => {
          if (result) {
            const generatedEvents: any[] = [];

            for (const meeting of result) {
              const title = `${meeting.name}, ${meeting.schedule.format === 'DIGITAL' ? 'Digital' : 'Fisico'}, ${meeting.schedule.host}`;

              let start = new Date(meeting.schedule.date);
              const [hour, minute] = meeting.schedule.initialHour.split(':');
              const parsedHour = parseInt(hour, 10);
              const parsedMinute = parseInt(minute, 10);
              start.setHours(parsedHour, parsedMinute, 0);

              //start = new Date(start.getTime() - start.getTimezoneOffset() * 60000);

              let end = new Date(meeting.schedule.date);
              const [hourE, minuteE] = meeting.schedule.endHour.split(':');
              const parsedHourE = parseInt(hourE, 10);
              const parsedMinuteE = parseInt(minuteE, 10);
              end.setHours(parsedHourE, parsedMinuteE, 0);
              const color = 'white';
              start.setDate(start.getDate() + 1);
              end.setDate(end.getDate() + 1);

              //end = new Date(end.getTime() - end.getTimezoneOffset() * 60000);
              //let start_formatted = start.toISOString().slice(0, 19);
              //let end_formatted = end.toISOString().slice(0, 19);
              generatedEvents.push({
                id: meeting.id,
                title,
                start: start,
                end: end,
                color: '#ffffff52',
                host: meeting.schedule.host,
                format: meeting.schedule.format
              });
            }
            setMeetings(generatedEvents);
          }
        });
      }
    };
    _getMeetings();
  }, []);

  const handleEventContent = (arg: any) => {
    const format = arg.event._def.extendedProps.format;
    const host = arg.event._def.extendedProps.host;
    const title = arg.event._def.title;
    if (format === 'DIGITAL') {
      return (
        <div>
          {title} - <a href={host}>Ir a reunion</a>{' '}
        </div>
      );
    } else {
      return <div>{title}</div>;
    }
  };

  const handleClose = (event: any, reason: any) => {
    //Avoid to close the modal when click outside
    if (reason === 'backdropClick') {
      event.stopPropagation();
      return;
    }
    seteventSelected({
      title: '',
      start: '',
      date: '',
      end: '',
      format: '',
      host: ''
    });
  };

  const handleSelect = (arg: any) => {
    seteventSelected({
      title: arg.event._def.title,
      date: dayjs(arg.event.start)
        .locale('es')
        .format('dddd, MMMM DD')
        .toString(),
      start: dayjs(arg.event.start)
        .format('HH:mm A')
        .toString(),
      end: dayjs(arg.event.end)
        .format('HH:mm A')
        .toString(),
      format: arg.event.extendedProps.format,
      host: arg.event.extendedProps.host
    });
    setmodalOpen(true);
  };

  return (
    <>
      <FullCalendar
        height="75vh"
        locales={allLocales}
        locale={'es'}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        editable={false}
        selectable={true}
        eventClick={handleSelect}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        }}
        initialView="listMonth"
        //eventsSet={events => setMeetings(events)}
        events={meetings}
        //eventContent={handleEventContent}
      ></FullCalendar>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        maxWidth="md"
        sx={{
          zIndex: 1001
        }}
        PaperProps={{ sx: { width: '30%', backgroundColor: colors.primary[400], zIndex: 10000 } }}
      >
        <DialogTitle
          variant="h2"
          color={colors.grey[100]}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          Detalles
          <IconButton onClick={() => setmodalOpen(false)} color="primary">
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: 'fit-content', overflow: 'hidden' }}>
          <Box display={'flex'}>
            <Typography variant="h3" color={colors.greenAccent[400]}>
              {eventSelected.title}
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="h6">
              {eventSelected.date} · {eventSelected.start} - {eventSelected.end}
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} marginTop={'1em'}>
            <Typography variant="h4">Formato: {eventSelected.format === 'DIGITAL' ? 'Virtual' : 'Presencial'}</Typography>
            {eventSelected.format === 'DIGITAL' && (
              <>
                <Button color="secondary" variant="contained" href={eventSelected.host} target="_blank">
                  Click para unirme a la reunión
                </Button>
                <span style={{ alignSelf: 'center' }}>{eventSelected.host}</span>
              </>
            )}

            {eventSelected.format !== 'DIGITAL' && (
              <>
                <span>{eventSelected.host}</span>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default CalendarView;
