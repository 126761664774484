import axios, { Axios, AxiosError, AxiosResponse } from 'axios';
import { _constants } from '../../constants/values';

const headers = {
  'Accept-Language': 'ES'
};

export class meetingServices {
  public async getMeetings(token: string, data: string): Promise<any> {
    const url = `${_constants.API_HOST}/meeting?email=${data}`;
    const response = await axios.get(url, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  /**
   * Service to retrieve all meetings from an specific event
   * @param token access token for auth
   * @returns
   */
  public async getMeetingsFromEvent(token: string, id: any): Promise<any> {
    const url = `${_constants.API_HOST}/meeting?id=${id}`;
    const response = await axios.get(url, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    console.log(response);

    return response.data;
  }

  public async createMeeting(token: string, data: any): Promise<any> {
    const url = `${_constants.API_HOST}/meeting`;
    const response = await axios.post(url, data, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async updateMeeting(token: string, data: any): Promise<any> {
    const url = `${_constants.API_HOST}/meeting`;
    const response = await axios.put(url, data, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  }

  public async uploadMeetingFile(token: string, event: any, file: any): Promise<any> {
    const url = `${_constants.API_HOST}/file?event=${event}`;
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async getMeetingFiles(token: string, event: any) {
    const url = `${_constants.API_HOST}/file?event=${event}`;
    const response = await axios.get(url, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async getMeetingsPDF(token: string, body: any, name: string) {
    const url = `${_constants.API_HOST}/summary?user=${name}`;
    const response = await axios.post(url, body, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }
}
