import { Box, IconButton, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Menu, MenuItem, Sidebar, useProSidebar } from 'react-pro-sidebar';
//import 'react-pro-sidebar/dist/css/styles.css';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import EventIcon from '@mui/icons-material/Event';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SwitchLeftOutlinedIcon from '@mui/icons-material/SwitchLeftOutlined';
import SwitchRightOutlinedIcon from '@mui/icons-material/SwitchRightOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { Link, To } from 'react-router-dom';
import avatar from '../../assets/user.png';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { tokens } from '../../theme';

interface menuItem {
  title: String;
  to: To;
  icon: any;
  selected: String;
  setSelected: any;
}

const Item = ({ title, to, icon, selected, setSelected }: menuItem) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100]
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      {title}
    </MenuItem>
  );
};

export interface IAppProps {}

function AppSidebar(props: IAppProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');
  const { sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const authContext = useAuthContext();

  const isUser = authContext ? (['USER', 'CHAINUSER'].includes(authContext.user.role) ? true : false) : true;
  return (
    <Box
      sx={{
        position: 'sticky',
        display: 'flex',
        height: '100vh',
        top: 0,
        bottom: 0,
        zIndex: 1000,
        '& .sidebar': {
          border: 'none'
        },
        '& .ps-menu-icon': {
          backgroundColor: 'transparent !important'
        },
        '& .ps-menu-button': {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: 'transparent !important'
        },
        '& .ps-menu-anchor': {
          color: 'inherit !important',
          backgroundColor: 'transparent !important'
        },
        '& .ps-menu-button:hover': {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: 'transparent !important'
        },
        '& .ps-menu-button.active': {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: 'transparent !important'
        }
      }}
    >
      <Sidebar breakPoint="md" backgroundColor={colors.primary[400]} image={sidebarImage}>
        <Menu>
          <MenuItem
            icon={collapsed ? <MenuOutlinedIcon onClick={() => collapseSidebar()} /> : null}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100]
            }}
          >
            {!collapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]}>
                  OneMeetPlace
                </Typography>
                <IconButton onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  '& .avater-image': {
                    backgroundColor: colors.primary[500]
                  }
                }}
              >
                <img
                  className="avater-image"
                  alt="profile user"
                  width="100px"
                  height="100px"
                  src={avatar}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h3" color={colors.grey[100]} fontWeight="bold" sx={{ m: '10px 0 0 0' }}>
                  {authContext ? authContext.user.name : ''}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={collapsed ? undefined : '10%'}>
            <Item title="Home" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
            {!isUser && (
              <>
                <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 20px 5px 20px' }}>
                  Eventos
                </Typography>
                <Item title="Eventos" to="/events" icon={<EventIcon />} selected={selected} setSelected={setSelected} />
                <Item title="Contactos" to="/users" icon={<ContactsOutlinedIcon />} selected={selected} setSelected={setSelected} />
              </>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
}

export default AppSidebar;
