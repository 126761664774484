import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridCellParams, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Header } from '../../components/header/Header';
import { useAuthContext } from '../../contexts/auth/AuthContext';
import { Country, EventResponse } from '../../models/common';
import { MeetingTypes } from '../../models/enums/meeting-creation-types';
import { eventServices } from '../../services/events/events';
import { tokens } from '../../theme';
import Content from '../global/Content';
import MainWrapper from '../global/MainWrapper';
import EventForm from './EventForm';

export interface IAppProps {}

interface RowData {
  id: string;
  name: string;
  initialDate: Date | null;
  endDate: Date | null;
  enable: boolean;
  countries: Array<string>;
  meetingConfiguration: any;
}

function Events(props: IAppProps) {
  const [modalOpen, setmodalOpen] = React.useState<boolean>(false);
  const [isEditing, setisEditing] = React.useState<boolean>(false); //Var to define if the modal form opens in edit mode
  const [initialData, setinitialData] = React.useState<any>(null);
  const [rows, setRows] = React.useState<any>([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const fullSize = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useAuthContext();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 0.1 },
    {
      field: 'name',
      headerName: 'Nombre',
      cellClassName: 'name-column--cell',
      flex: 0.2
    },
    {
      field: 'initialDate',
      headerName: 'Inicio',
      type: 'date',
      flex: 0.1,
      valueFormatter: params => dayjs(params.value).format('YYYY-MM-DD')
    },
    {
      field: 'endDate',
      headerName: 'Fin',
      type: 'date',
      flex: 0.1,
      valueFormatter: params => dayjs(params.value).format('YYYY-MM-DD')
    },
    {
      field: 'enable',
      headerName: 'Activo',
      type: 'boolean',
      flex: 0.05
    },
    {
      field: 'countries',
      headerName: 'Participantes',
      renderCell: ({ row: { countries } }) => {
        return (
          <>
            {countries.map((country: any, idx: number) => {
              return <ReactCountryFlag key={idx} countryCode={country} title={country} />;
            })}
          </>
        );
      },
      flex: 0.2
    },
    {
      field: 'meetingDuration',
      headerName: 'Duración reuniones',
      valueGetter: (params: any) => {
        return parseInt(params.row.meetingConfiguration.meetingDuration);
      },
      flex: 0.15
    },
    {
      field: 'meetingIdleTime',
      headerName: 'IDLE time',
      valueGetter: (params: any) => {
        return parseInt(params.row.meetingConfiguration.meetingIdleTime);
      },
      flex: 0.1
    },
    {
      field: 'dailyNotificationTime',
      headerName: 'Hora notificación',
      type: 'string',
      valueGetter: (params: any) => {
        return params.row.meetingConfiguration.dailyNotificationTime;
      },
      flex: 0.1
    },
    {
      field: '',
      sortable: false,
      renderCell: (params: any) => (
        <>
          <IconButton onClick={() => handleEdit(params)}>
            <EditOutlinedIcon />
          </IconButton>
          <Link to={`/events/${params.row.id}/meetings`}>
            <IconButton onClick={() => handleEdit(params)}>
              <DateRangeOutlinedIcon />
            </IconButton>
          </Link>
        </>
      )
    }
  ];

  const rowsExample: any = [
    {
      id: 1,
      name: 'evento 1',
      initialDate: '2023-07-25',
      endDate: '2023-07-27',
      enable: true,
      countries: ['GT', 'MX'],
      meetingConfiguration: {
        meetingDuration: 15,
        meetingIdleTime: 5,
        dailyNotificationTime: '17:30'
      }
    },
    {
      id: 2,
      name: 'evento 2',
      initialDate: new Date(2023, 8, 25),
      endDate: new Date(2023, 7, 25),
      enable: false,
      countries: ['GT', 'MX'],
      meetingConfiguration: {
        meetingDuration: 20,
        meetingIdleTime: 2,
        dailyNotificationTime: '20:30'
      }
    },
    {
      id: 3,
      name: 'evento 3',
      initialDate: new Date(2023, 9, 25),
      endDate: new Date(2023, 7, 25),
      enable: true,
      countries: ['GT', 'MX'],
      meetingConfiguration: {
        meetingDuration: 30,
        meetingIdleTime: 15,
        dailyNotificationTime: '07:00'
      }
    },
    {
      id: 4,
      name: 'evento 4',
      initialDate: new Date(2023, 9, 25),
      endDate: new Date(2023, 7, 25),
      enable: true,
      countries: ['GT', 'MX'],
      meetingConfiguration: {
        meetingDuration: 25,
        meetingIdleTime: 2,
        dailyNotificationTime: '10:00'
      }
    }
  ];

  React.useEffect(() => {
    //From here I'll do fetch from the api to retrieve all events from db for current user
    const fetchData = async () => {
      try {
        if (authContext) {
          const service = new eventServices();
          const response = (await service.getEvents(authContext.user.token)) as EventResponse;
          //Change the attributes that need the UI
          //const jsonData = await response.json();
          //console.log(jsonData);
          console.log(
            response.events.map(e => ({
              ...e,
              countries: e.countries.map(ex => ex.country)
            }))
          );
          setRows(
            response.events.map(e => ({
              ...e,
              countries: e.countries.map(ex => ex.country)
            }))
          );
          //setRows(rowsExample);
        }
      } catch (error) {
        setRows([]);
      }
    };

    fetchData();
    //setRows(rowsExample);
  }, [modalOpen]);

  /**
   * Method to handle the close event from the modal form
   * @param event event triggered on the dialog
   * @param reason reason that closes the dialog
   * @returns void
   */
  const handleClose = (event: any, reason: any) => {
    //Avoid to close the modal when click outside
    if (reason === 'backdropClick') {
      event.stopPropagation();
      return;
    }

    setmodalOpen(false);
  };

  /**
   * Method to handle the open button for the modal form
   */
  const handleOpen = () => {
    resetFormValues();
    setisEditing(false); //Set form in edit mode
    setmodalOpen(true); //Open modal form
  };

  /**
   * Method to handle the edit button in each row, only open the form in edit mode
   * @param params contains all the information from the clicked row
   */
  const handleEdit = (params: GridCellParams) => {
    setinitialData({
      id: params.row.id,
      name: params.row.name,
      initialDate: dayjs(params.row.initialDate).format('YYYY-MM-DD'),
      endDate: dayjs(params.row.endDate).format('YYYY-MM-DD'),
      countries: params.row.countries,
      enable: true,
      meetingConfiguration: {
        meetingDuration: params.row.meetingConfiguration.meetingDuration,
        meetingIdleTime: params.row.meetingConfiguration.meetingIdleTime,
        dailyNotificationTime: params.row.meetingConfiguration.dailyNotificationTime
      }
    });
    setisEditing(true); //Set form in edit mode
    setmodalOpen(true); //Open modal form
  };

  /**
   *
   * @param updatedItem functions that calls api to update event
   */
  const editEvent = async (_updatedItem: any) => {
    const service = new eventServices();

    const updatedItem = JSON.parse(JSON.stringify(_updatedItem));
    const countries = updatedItem.countries as Array<string>;

    updatedItem.countries = countries.map(e => {
      return {
        country: e
      };
    });
    updatedItem.meetingConfiguration.typeMeeting = MeetingTypes.DURATION;

    if (authContext) {
      try {
        const response = await service.updateEvent(authContext.user.token, {
          type: 'event',
          input: { ...updatedItem }
        });

        Swal.fire({
          title: 'Yayy!',
          text: `${response}`,
          icon: 'success'
        });
        resetFormValues(); //Reset values
        setmodalOpen(false); //Close modal
      } catch (error) {
        const response = error as AxiosError;
        const message = response.response!.data as any;
        Swal.fire({
          title: 'Error!',
          text: `${message}`,
          icon: 'error'
        });
      }
    }
  };

  /**
   *
   * @param newItem function that calls api to create new event
   */
  const handleCreate = async (newItem: any) => {
    //Create the new event
    const service = new eventServices();
    const countries = newItem.countries as Array<string>;

    delete newItem.id;
    newItem.countries = countries.map(e => {
      return {
        country: e
      };
    });
    newItem.meetingConfiguration.typeMeeting = MeetingTypes.DURATION;

    if (authContext) {
      try {
        const response = await service.createEvent(authContext.user.token, {
          type: 'event',
          input: { ...newItem }
        });
        Swal.fire({
          title: 'Yayy!',
          text: `${response}`,
          icon: 'success'
        });
        resetFormValues(); //Reset values
        setmodalOpen(false); //Close modal
      } catch (error) {
        const response = error as AxiosError;
        const message = response.response!.data as any;
        Swal.fire({
          title: 'Error!',
          text: `${message}`,
          icon: 'error'
        });
      }
    }

    //setRows([...rows, newItem]); //Add new label to state array
    //fetchData();
  };

  /**
   * Function to reset default form values
   */
  const resetFormValues = () => {
    setinitialData({
      id: null,
      name: '',
      initialDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      countries: [],
      enable: true,
      meetingConfiguration: {
        meetingDuration: 25,
        meetingIdleTime: 5,
        dailyNotificationTime: '07:00'
      }
    });
  };

  return (
    <MainWrapper>
      <Content title="EVENTOS" subtitle="Gestiona tus eventos">
        <Box display="flex" justifyContent="end" mt="20px">
          <Button color="secondary" variant="contained" onClick={handleOpen}>
            Crear evento
          </Button>
        </Box>
        <Box
          m="8px 0 0 0"
          height="80vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300]
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none'
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400]
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700]
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`
            }
          }}
        >
          <DataGrid rows={rows} columns={columns} />
        </Box>
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          maxWidth="md"
          sx={{
            zIndex: 1001
          }}
          PaperProps={{ sx: { width: '100%', backgroundColor: colors.primary[400], zIndex: 10000 } }}
        >
          <DialogTitle
            variant="h2"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Evento
            <IconButton onClick={() => setmodalOpen(false)} color="primary">
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: 'fit-content', overflow: 'hidden' }}>
            <EventForm isEditing={isEditing} initialValues={initialData} handleCreate={handleCreate} editEvent={editEvent} />
          </DialogContent>
        </Dialog>
      </Content>
    </MainWrapper>
  );
}

export default Events;
