import axios, { Axios, AxiosError, AxiosResponse } from 'axios';
import { _constants } from '../../constants/values';

const headers = {
  'Accept-Language': 'ES'
};

export interface loginResponse {
  statusCode: number;
  message: string;
}

export class authServices {
  public async login(data: any): Promise<AxiosResponse | AxiosError> {
    try {
      //const url = `${_constants.API_HOST}:${_constants.API_PORT}/login`;

      const url = `${_constants.API_HOST}/login`;
      console.log(url);
      const body = {
        type: 'authenticate',
        input: data
      };
      const response = await axios.post(url, body, { headers });
      return response;
    } catch (exception) {
      return exception as AxiosError;
    }
  }
}
