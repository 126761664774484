import axios from 'axios';
import { _constants } from '../../constants/values';

export class contactService {
  //API GET call to retrieve all events for specific company
  public async getUsers(token: string): Promise<any> {
    const response = await axios.get(`${_constants.API_HOST}/user`, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async getUsersForMeeting(token: string): Promise<any> {
    const response = await axios.get(`${_constants.API_HOST}/user?target=agenda`, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }
}
