import axios from 'axios';
import { _constants } from '../../constants/values';

export class eventServices {
  //API GET call to retrieve all events for specific company
  public async getEvents(token: string): Promise<any> {
    const response = await axios.get(`${_constants.API_HOST}/event`, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async createEvent(token: string, data: any): Promise<any> {
    const response = await axios.post(`${_constants.API_HOST}/event`, data, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async updateEvent(token: string, data: any): Promise<any> {
    const response = await axios.put(`${_constants.API_HOST}/event`, data, {
      headers: {
        'Accept-Language': 'ES',
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }

  public async get(url: string): Promise<any> {
    const response = await axios.get(url);
    return response.data;
  }

  public async post(url: string, body: any): Promise<any> {
    console.log('body-post', body);
    const response = await axios.post(url, body);
    return response.data;
  }
}
