import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authServices } from '../../services/auth/auth';
import { useLocalStorage } from './useLocalStorage';

type myState = myObject | null;

interface myObject {
  email: string;
}

interface loginResponseFail {
  message: string;
}

interface loginResponseSuccess {
  email: string;
}

export const useAuth = () => {
  const [user, setUser] = useLocalStorage('user', null);
  const _authServices = new authServices();
  const navigate = useNavigate();
  const location = useLocation();

  const login = async (data: any) => {
    //Consume login service here
    const response = await _authServices.login({ email: data.email, password: data.password });

    if (response instanceof AxiosError) {
      return { statusCode: response.status, message: response.response!.data };
    }

    let now = new Date();
    setUser({
      email: response.data.data.email,
      name: response.data.data.name,
      role: response.data.data.role,
      token: response.data.access_token,
      expiration: now.setHours(now.getHours() + response.data.data.durationSession)
    });

    navigate(location.state ? location.state.from : '/');
    return null;
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate('/login');
  };

  return { user, login, logout };
};
